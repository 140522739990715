import React, { useRef } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { LayoutDropZone } from './LayoutDropZone';
import { Group } from './Group';

interface ComponentProps {
  data: LayoutNode;
  path: string;
  handleDrop: Function;
  labels: Array<LabelItem>;
  onLabelChange: (nodeId: string, label: string) => void;
}

export const Row = ({ data, handleDrop, path, labels, onLabelChange }: ComponentProps) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'ROW',
      item: {
        type: 'ROW',
        id: data.id,
        children: data.children,
        path,
      },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [data, path],
  );

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderGroups = (group: LayoutNode, currentPath: string) => {
    return (
      <Group
        key={group.id}
        data={group}
        handleDrop={handleDrop}
        path={currentPath}
        labels={labels}
        onLabelChange={onLabelChange}
      />
    );
  };

  return (
    <div ref={ref} style={{ opacity }} className="base draggable row">
      <div className="groups">
        {data.children.map((group, index) => {
          const currentPath = `${path}-${index}`;

          return (
            <React.Fragment key={group.id}>
              <LayoutDropZone
                data={{
                  path: currentPath,
                  childrenCount: data.children.length,
                  relatedNodeType: 'ROW',
                }}
                onDrop={handleDrop}
                forType="ROW"
                className="horizontalDrag"
              />
              {renderGroups(group, currentPath)}
            </React.Fragment>
          );
        })}
        <LayoutDropZone
          data={{
            path: `${path}-${data.children.length}`,
            childrenCount: data.children.length,
            relatedNodeType: 'ROW',
          }}
          onDrop={handleDrop}
          className="horizontalDrag"
          forType="ROW"
          isLast
        />
      </div>
    </div>
  );
};

import React, { useRef } from 'react';
import { useDrag } from 'react-dnd';
import { LayoutDropZone } from './LayoutDropZone';
import Component from './Component';

const style = {};

interface ComponentProps {
  data: LayoutNode;
  path: string;
  handleDrop: Function;
  labels: Array<LabelItem>;
}

const Column = ({ data, handleDrop, path, labels }: ComponentProps) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'COLUMN',
      item: {
        type: 'COLUMN',
        id: data.id,
        children: data.children,
        path,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [data, path],
  );

  const opacity = isDragging ? 0.5 : 1;
  drag(ref);

  const renderComponent = (component: LayoutNode, currentPath: string) => {
    return (
      <Component
        key={component.id}
        data={component}
        path={currentPath}
        label={labels.find((item) => item.key === component.id)}
      />
    );
  };

  if (!Array.isArray(data.children)) {
    return null;
  }
  return (
    <div ref={ref} style={{ ...style, opacity }} className="base draggable column">
      {data.children.map((component, index) => {
        const currentPath = `${path}-${index}`;

        return (
          <React.Fragment key={component.id}>
            <LayoutDropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length,
                relatedNodeType: 'COLUMN',
              }}
              onDrop={handleDrop}
              isLast={false}
              forType="COLUMN"
            />
            {renderComponent(component, currentPath)}
          </React.Fragment>
        );
      })}
      <LayoutDropZone
        data={{
          path: `${path}-${data.children.length}`,
          childrenCount: data.children.length,
          relatedNodeType: 'COLUMN',
        }}
        onDrop={handleDrop}
        isLast
        forType="COLUMN"
      />
    </div>
  );
};
export default Column;

import { v4 as uuidv4 } from 'uuid';
import { uniqueid } from './utils';
import { cloneDeep } from 'lodash';

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed); // inserting task in new index

  return result;
};

export const remove = (arr, index) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // part of the array after the specified index
  ...arr.slice(index + 1),
];

export const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

export const reorderChildren = (children, splitDropZonePath, splitItemPath) => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    const itemIndex = Number(splitItemPath[0]);
    return reorder(children, itemIndex, dropZoneIndex);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitDropZonePath.slice(0, 1));

  // Update the specific node's children
  const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
  const splitItemChildrenPath = splitItemPath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: reorderChildren(nodeChildren.children, splitDropZoneChildrenPath, splitItemChildrenPath),
  };

  return updatedChildren;
};

export const removeChildFromChildren = (children, splitItemPath) => {
  if (splitItemPath.length === 1) {
    const itemIndex = Number(splitItemPath[0]);
    return remove(children, itemIndex);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitItemPath.slice(0, 1));

  // Update the specific node's children
  const splitItemChildrenPath = splitItemPath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: removeChildFromChildren(nodeChildren.children, splitItemChildrenPath),
  };

  return updatedChildren;
};

export const addChildToChildren = (children, splitDropZonePath, item) => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    return insert(children, dropZoneIndex, item);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitDropZonePath.slice(0, 1));

  // Update the specific node's children
  const splitItemChildrenPath = splitDropZonePath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: addChildToChildren(nodeChildren.children, splitItemChildrenPath, item),
  };

  return updatedChildren;
};

export const handleMoveWithinParent = (layout, splitDropZonePath, splitItemPath) => {
  return reorderChildren(layout, splitDropZonePath, splitItemPath);
};

export const handleAddGroupToRow = (layout) => {
  const layoutCopy = cloneDeep(layout);
  const GROUP_STRUCTURE = {
    type: 'GROUP',
    id: uniqueid('GRP'),
    children: [],
  };

  return layoutCopy.map((row) => {
    if (!row.children.length) {
      row.children = [GROUP_STRUCTURE];
    }
    return row;
  });
};

export const handleMoveToDifferentParent = (layout, splitDropZonePath, splitItemPath, item, dropZoneNode) => {
  let newLayoutNode;

  const GROUP_STRUCTURE = {
    type: 'GROUP',
    id: uniqueid('GRP'),
    children: [
      {
        type: 'COLUMN',
        id: uniqueid('COL'),
        children: [item], // @TODO 'item' is only correct if item.type === 'COL'
      },
    ],
  };

  const COLUMN_STRUCTURE = {
    type: 'COLUMN',
    id: uniqueid('COL'),
    children: [item],
  };

  const ROW_STRUCTURE = {
    type: 'ROW',
    id: uuid(),
  };

  switch (splitDropZonePath.length) {
    case 1: {
      // Moving component outside into a dropzone above a row which creates new row with group and column
      // eg: by dropping a component from a column onto the top of the layout template.
      if (['COMPONENT', 'FIELD_FRAGMENT'].includes(item.type) && dropZoneNode?.relatedNodeType === 'ROW') {
        newLayoutNode = {
          ...ROW_STRUCTURE,
          children: [GROUP_STRUCTURE],
        };
      }
      // moving group outside into new row made on the fly
      else if (item.type === 'GROUP') {
        newLayoutNode = {
          type: 'ROW',
          id: uuid(),
          children: [item],
        };
      } else if (item.type === 'COLUMN') {
        newLayoutNode = {
          type: 'ROW',
          id: uuid(),
          children: [
            {
              type: 'GROUP',
              id: uniqueid('GRP'),
              children: [item],
            },
          ],
        };
      } else {
        // moving component outside into new row made on the fly
        newLayoutNode = {
          ...ROW_STRUCTURE,
          children: [GROUP_STRUCTURE],
        };
      }
      break;
    }
    case 2: {
      // moving component outside into a row which creates column
      if (['COMPONENT', 'FIELD_FRAGMENT'].includes(item.type) && dropZoneNode?.relatedNodeType === 'ROW') {
        newLayoutNode = GROUP_STRUCTURE;
        // move a Group
      } else if ('GROUP' === item.type && dropZoneNode?.relatedNodeType === 'ROW') {
        // moving group into existing row
        newLayoutNode = item;
      } else if ('COLUMN' === item.type && dropZoneNode?.relatedNodeType === 'ROW') {
        // Moving column into existing row, wrap Column with Group
        newLayoutNode = {
          type: 'GROUP',
          id: uniqueid('GRP'),
          children: [cloneDeep(item)],
        };
      } else {
        // moving column into existing row
        newLayoutNode = item;
      }

      break;
    }
    case 3: {
      // moving component outside into a row which creates column
      if (['COMPONENT', 'FIELD_FRAGMENT'].includes(item.type) && dropZoneNode?.relatedNodeType === 'GROUP') {
        newLayoutNode = COLUMN_STRUCTURE;
      } else {
        // moving column into existing row
        newLayoutNode = item;
      }
      break;
    }
    default: {
      newLayoutNode = item;
    }
  }

  let updatedLayout = cloneDeep(layout);

  if (newLayoutNode) {
    updatedLayout = removeChildFromChildren(updatedLayout, splitItemPath);
    // updatedLayout = handleAddGroupToRow(updatedLayout);
    updatedLayout = addChildToChildren(updatedLayout, splitDropZonePath, newLayoutNode);
  }

  return updatedLayout;
};

export const handleMoveSidebarComponentIntoParent = (layout, splitDropZonePath, item) => {
  let newLayoutNode;
  switch (splitDropZonePath.length) {
    // drop onto empty layout
    case 1: {
      newLayoutNode = {
        type: 'ROW',
        id: uuid(),
        children: [
          { type: 'GROUP', id: uniqueid('GRP'), children: [{ type: 'COLUMN', id: uniqueid('COL'), children: [item] }] },
        ],
      };
      break;
    }
    // drop onto row
    case 2: {
      newLayoutNode = {
        type: 'GROUP',
        id: uniqueid('GRP'),
        children: [{ type: 'COLUMN', id: uniqueid('COL'), children: [item] }],
      };
      break;
    }
    // drop onto ...
    case 3: {
      newLayoutNode = {
        type: 'COLUMN',
        id: uniqueid('COL'),
        children: [item],
      };
      break;
    }
    default: {
      newLayoutNode = item;
    }
  }

  return addChildToChildren(layout, splitDropZonePath, newLayoutNode);
};

export const handleRemoveItemFromLayout = (layout, splitItemPath) => {
  return removeChildFromChildren(layout, splitItemPath);
};

export function uuid() {
  return uuidv4();
}

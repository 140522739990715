import { LayoutColumn } from './LayoutColumn';
import { CommonLayoutProps } from './LayoutComponent';
import { LayoutGroup } from './LayoutGroup';

interface ComponentProps extends CommonLayoutProps {
  data: LayoutNode;
  fieldFragmentsMap: Map<string, FieldInfo>;
}

export const LayoutRow = ({ data, fieldFragmentsMap, ...rest }: ComponentProps) => {
  const hasGroups = data.children.some((item) => item.type === 'GROUP');
  if (hasGroups) {
    return (
      <div className="flex gap-x-8">
        {data.children.map((layoutNode) => (
          <LayoutGroup key={layoutNode.id} data={layoutNode} fieldFragmentsMap={fieldFragmentsMap} {...rest} />
        ))}
      </div>
    );
  }
  // @TODO remove after all templates use LayoutNodes with type GROUP
  return (
    <div className="p-6 bg-white">
      <div className="flex gap-x-8">
        {data.children.map((layoutNode) => (
          <LayoutColumn key={layoutNode.id} data={layoutNode} fieldFragmentsMap={fieldFragmentsMap} {...rest} />
        ))}
      </div>
    </div>
  );
};

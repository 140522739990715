import { FC } from 'react';
import { useDrop } from 'react-dnd';

const ACCEPTS: Array<LayoutNodeType> = ['SIDEBAR_ITEM', 'COMPONENT', 'ROW', 'COLUMN', 'FIELD_FRAGMENT', 'GROUP'];

interface ComponentProps {
  data: LayoutNodeRenderInfo;
  onDrop: Function;
  isLast?: boolean;
  className?: string;
  forType: LayoutNodeType;
}

export const LayoutDropZone: FC<ComponentProps> = ({ data, onDrop, isLast, className }) => {

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ACCEPTS,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: (item) => {
        onDrop(data, item);
      },
      canDrop: (item: any, _monitor) => {
        const dropZonePath = data.path;
        const splitDropZonePath = dropZonePath.split('-');
        const itemPath = item.path;

        // sidebar items can always be dropped anywhere
        if (!itemPath) {
          // if (data.childrenCount >= 3) {
          //  return false;
          // }
          return true;
        }

        const splitItemPath = itemPath.split('-');

        // limit columns when dragging from one row to another row
        const dropZonePathRowIndex = splitDropZonePath[0];
        const itemPathRowIndex = splitItemPath[0];
        const diffRow = dropZonePathRowIndex !== itemPathRowIndex;
        if (diffRow && splitDropZonePath.length === 2 && data.childrenCount >= 3) {
          return false;
        }

        // Invalid (Can't drop a parent element (row) into a child (column))
        const parentDropInChild = splitItemPath.length < splitDropZonePath.length;
        if (parentDropInChild) {
          return false;
        }

        // Current item can't possible move to it's own location
        if (itemPath === dropZonePath) {
          return false;
        }

        // Current area
        if (splitItemPath.length === splitDropZonePath.length) {
          const pathToItem = splitItemPath.slice(0, -1).join('-');
          const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

          const pathToDropZone = splitDropZonePath.slice(0, -1).join('-');
          const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

          if (pathToItem === pathToDropZone) {
            const nextDropZoneIndex = currentItemIndex + 1;
            if (nextDropZoneIndex === currentDropZoneIndex) {
              return false;
            }
          }
        }

        return true;
      },
    }),
    [data, onDrop],
  );

  const isActive = isOver && canDrop;

  return (
    <div
      className={`dropZone ${className ? className : ''} ${isActive ? 'active' : ''} ${isLast ? 'isLast' : ''} flex justify-center`}
      ref={drop}
    />
  );
};

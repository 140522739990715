import { createSlice } from '@reduxjs/toolkit';
import { getResponseError } from '../../api';
import { RootState } from '../../app/store';
import { findOnDay } from './actions';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import {getName} from '../entries/utils';
import {userDisplayName} from '../users/utils';

interface checksState {
  error: boolean | null;
  isWaiting: boolean;
  shops: Shop[] | null;
  shopEntries: any[] | null;
  fieldDefinitions: FieldDefinition[] | null,
  fieldDefinitionSummary: FieldDefinitionSummaryNew[] | null;
  fieldFragmentSummary: FieldFragmentSummaryNew[] | null;
  fieldDefinitionTotalSummary: Record<string, number> | null;
  roles: {[Key in UserRole]?: User[] } | null;
  message: string;
}

const initialState: checksState = {
  error: false,
  isWaiting: false,
  shops: null,
  shopEntries: null,
  message: '',
  fieldDefinitions: null,
  fieldDefinitionSummary: null,
  fieldFragmentSummary: null,
  fieldDefinitionTotalSummary: null,
  roles: null,
}

function resetData(state:checksState) {
  state.shops= null;
  state.shopEntries= null;
  state.fieldDefinitions= null;
  state.fieldDefinitionSummary= null;
  state.fieldDefinitionTotalSummary= null;
  state.fieldFragmentSummary= null;
}

const getFieldFragmentsInFieldDefinitions = (fieldDefinitions:FieldDefinition[]|null) => {
  if (fieldDefinitions === null) { return []; }
  return fieldDefinitions
  .map((fieldDefinition:FieldDefinition) => fieldDefinition.fieldFragments)
  .flat()
  .filter((fieldFragment:FieldFragment) => fieldFragment.inputType === 'number');
};

function pending(state:any, message:string = '') {
  state.error = null;
  state.isWaiting = true;
  state.message = message;
}

function fulfilled(state:any, message:string = '') {
  state.error = false;
  state.isWaiting = false;
  state.message = message;
}

function rejected(state:any, message:string = '') {
  state.error = true;
  state.isWaiting = false;
  state.message = message;
}

export const checksSlice = createSlice({
  name: 'checks',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    // GET SINGLE ENTRY
    .addCase(findOnDay.pending, (state) => {
      pending(state);
    })
    .addCase(findOnDay.fulfilled, (state, action) => {
      fulfilled(state);
      const { shops, entries, fieldDefinitionSummary, fieldFragmentSummary } = action.payload;
      if (shops && entries) {
        state.shops = action.payload.shops || null;
        state.fieldDefinitions = action.payload.fieldDefinitions || null;
        state.fieldDefinitionSummary = fieldDefinitionSummary || null;
        state.fieldFragmentSummary = fieldFragmentSummary || null;
        state.fieldDefinitionTotalSummary = action.payload.fieldDefinitionTotalSummary || null;
        state.roles = action.payload.roles || null;
        state.shopEntries = shops.map((shop:Shop) => {
          const columns:{key: string, value: number}[] = [
            // FieldFragment summary (has the entry for the entry date in 'fieldFragment_calc_inputOfDay'
            ...fieldFragmentSummary
            .filter((entry:FieldFragmentSummaryNew) => entry.shop && entry.shop.id === shop.id)
            .map((entry:FieldFragmentSummaryNew) => ({ key: entry.key, value: entry.fieldFragment_calc_inputOfDay })),
            // FieldDefinition summary
            ...fieldDefinitionSummary
            .filter((entry:FieldDefinitionSummaryNew) => entry.shop && entry.shop.id === shop.id)
            .map((entry:FieldDefinitionSummaryNew) => ({ key: entry.key, value: entry.fieldDefinition_calc_endOfDay })),
          ];
          const data = new Map(Object.entries({
            id: shop.id,
            key: shop.id,
            name: shop.name,
          }));
          columns.forEach(column => data.set(column.key, column.value));
          return Object.fromEntries(data);
        });
      } else {
        resetData(state);
      }
    })
    .addCase(findOnDay.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      resetData(state);
    })
    .addDefaultCase(() => {}),
});

// SELECTORS
export const selectShops = (state: RootState) => state.checks.shops;
export const selectShopEntries = (state: RootState) => state.checks.shopEntries || [];
export const selectFieldDefinitions = (state: RootState) => state.checks.fieldDefinitions || [];
export const selectShopEntriesSummary = (state: RootState) => state.checks.fieldDefinitionTotalSummary;
export const selectAvailableColumns = (state: RootState):ColumnDescription[] =>
sortBy(
[
  // list of FieldDefinitions
  ...uniqBy(state.checks.fieldDefinitionSummary, 'fieldDefinition.id'),
  // list of FieldFragments
  ...getFieldFragmentsInFieldDefinitions(state.checks.fieldDefinitions).map(item => ({...item, key: item.id})),
]
, (o) => ( getName(o) || '').toLowerCase() )
  || [];

export const selectAvailableFilters = (state: RootState):ColumnDescription[] => {
  return (state.checks.roles?.MANAGER || []).map((user:User) => ({
    key: user.id,
    name: userDisplayName(user),
    data: user.shops.map(item => item.id),
  }));
}

export const selectMessage = (state: RootState): string => state.checks.message;
export const selectErrorMessage = (state: RootState): string  => state.checks.error === true ? state.checks.message : '';
export const selectIsWaiting = (state: RootState): boolean => state.checks.isWaiting;

export default checksSlice.reducer;

import { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { Trans } from 'react-i18next';
import { useAppDispatch } from '../../../app/hooks';
import sortBy from 'lodash/sortBy';
import entriesService from '../entriesService';
import { userDisplayName } from '../../users/utils';
import { asHistoryDate, fieldFragmentName, formatAmount } from '../utils';

interface ItemProps {
  entryValue: EntryValue;
  entryValues: EntryValue[];
}

const Item = (props: ItemProps) => {
  const entryIndex = props.entryValues.findIndex((item) => item.id === props.entryValue.id);
  const inputType = 'inputType' in props.entryValue.fieldFragment ? props.entryValue.fieldFragment.inputType : '';
  const closestSameEntryIndex = props.entryValues.find(
    (item, idx) => idx > entryIndex && item.fieldFragment.id === props.entryValue.fieldFragment.id,
  );
  const isLastEntryForFieldFragment: boolean = !props.entryValues.find(
    (item, idx) => idx < entryIndex && item.fieldFragment.id === props.entryValue.fieldFragment.id,
  );

  // NOTE: One Item could show a "clear number" and a "changed" or add" number row
  return (
    <>
      {/** Last history item is a cleared number value */}
      {inputType === 'number' && props.entryValue?.deletedAt !== null && isLastEntryForFieldFragment && (
        <div className="mt-4 mb-4">
          <div className="flex gap-2">
            <Trans
              i18nKey={'entries.history.cleared-number'}
              values={{
                user: userDisplayName(props.entryValue.user),
                name: fieldFragmentName(props.entryValue.fieldFragment),
              }}
            />
          </div>
          <div className="text-gray-400">{asHistoryDate(props.entryValue.deletedAt || props.entryValue.version)}</div>
        </div>
      )}

      {/** History item is a number or text value */}
      <div className="mt-4 mb-4">
        <div className="flex gap-2">
          {inputType === 'number' && (
            <Trans
              i18nKey={closestSameEntryIndex ? 'entries.history.changed-number' : 'entries.history.added-number'}
              values={{
                user: userDisplayName(props.entryValue.user),
                name: fieldFragmentName(props.entryValue.fieldFragment),
                from: formatAmount(closestSameEntryIndex?.value),
                value: formatAmount(props.entryValue.value),
              }}
            />
          )}
          {inputType === 'text' && (
            <Trans
              i18nKey={'entries.history.added-text'}
              values={{
                user: userDisplayName(props.entryValue.user),
                name: fieldFragmentName(props.entryValue.fieldFragment),
                value: props.entryValue.textValue,
              }}
            />
          )}
        </div>
        <div className="text-gray-400">{asHistoryDate(props.entryValue.version)}</div>
      </div>
    </>
  );
};

interface HistoryProps {
  entry: Entry;
  onClose: Function;
}

export const History = (props: HistoryProps) => {
  const dispatch = useAppDispatch();

  const [localeHistory, setLocaleHistory] = useState<EntryValue[]>([]);
  const [hasHistory, setHasHistory] = useState<boolean | null>(null);

  // Fetch Entry with history
  useEffect(() => {
    if (hasHistory !== null) {
      return;
    }
    async function call() {
      if (!props.entry?.id) {
        return;
      }
      const result: any = await entriesService().findOne({ id: props.entry.id });
      if (Array.isArray(result.entryValues)) {
        setLocaleHistory(sortBy(result.entryValues, 'version').reverse());
        setHasHistory(result.entryValues.length > 0);
      } else {
        setHasHistory(false);
      }
    }
    call();
  }, [dispatch, props.entry, hasHistory]);

  if (!props.entry) {
    return null;
  }

  return (
    <Drawer
      title={<Trans i18nKey="History" />}
      size="large"
      placement="right"
      open={true}
      onClose={() => {
        props.onClose();
      }}
    >
      {!hasHistory && (
        <div>
          <Trans i18nKey="entries.no-history" />
        </div>
      )}
      {localeHistory.map((entryValue) => (
        <Item key={entryValue.id} entryValue={entryValue} entryValues={localeHistory} />
      ))}
    </Drawer>
  );
};

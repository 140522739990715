import { Link } from 'react-router-dom';

interface NavProps {
  shop: EntryShop | undefined;
  shops: EntryShop[] | null;
}
export const Nav = (props: NavProps) => {
  if (!props.shop || !props.shops) {
    return null;
  }
  if (props.shops.length === 0) {
    return null;
  }
  const currentIndex = props.shops.map((curr) => curr.id).indexOf(props.shop.id);
  const prevShop = currentIndex > 0 ? `/entries/shop/${props.shops[currentIndex - 1].id}` : '';
  const nextShop = currentIndex < props.shops.length - 1 ? `/entries/shop/${props.shops[currentIndex + 1].id}` : '';

  return (
    <>
      {prevShop && <Link to={prevShop}>&lt;</Link>}
      {nextShop && <Link to={nextShop}>&gt;</Link>}
    </>
  );
};

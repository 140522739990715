import React, { useRef } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { LayoutDropZone } from './LayoutDropZone';
import Column from './Column';
import { EditableHeader } from './EditableHeader';

interface ComponentProps {
  data: LayoutNode;
  path: string;
  handleDrop: Function;
  labels: Array<LabelItem>;
  onLabelChange: (nodeId: string, label: string) => void;
}

export const Group = ({ data, handleDrop, path, labels, onLabelChange }: ComponentProps) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'GROUP',
      item: {
        type: 'GROUP',
        id: data.id,
        children: data.children,
        path,
        name: data.name,
      },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [data, path],
  );

  const onLocalLabelChange = (label: string) => {
    onLabelChange(data.id, label);
  };

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderColumn = (column: LayoutNode, currentPath: string) => {
    return <Column key={column.id} data={column} handleDrop={handleDrop} path={currentPath} labels={labels} />;
  };

  return (
    <div ref={ref} style={{ opacity }} className="base draggable group">
      <EditableHeader initialText={data.name} onChange={onLocalLabelChange} />
      <div className="columns">
        {data.children.map((column, index) => {
          const currentPath = `${path}-${index}`;

          return (
            <React.Fragment key={column.id}>
              <LayoutDropZone
                data={{
                  path: currentPath,
                  childrenCount: data.children.length,
                  relatedNodeType: 'GROUP',
                }}
                onDrop={handleDrop}
                className="horizontalDrag"
                forType="GROUP"
              />
              {renderColumn(column, currentPath)}
            </React.Fragment>
          );
        })}
        <LayoutDropZone
          data={{
            path: `${path}-${data.children.length}`,
            childrenCount: data.children.length,
            relatedNodeType: 'GROUP',
          }}
          onDrop={handleDrop}
          className="horizontalDrag"
          forType="GROUP"
          isLast
        />
      </div>
    </div>
  );
};

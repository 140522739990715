import { format, add, isBefore, startOfToday } from 'date-fns';
import get from 'lodash/get';
import { allRoles } from '../users/constants';

export const entryDateFormat = 'yyyy-MM-dd';
export const historyDateFormat = 'yyyy-MM-dd HH:mm';

const digitCount = 2;
const localNumberConfig = {
  minimumFractionDigits: digitCount,
  maximumFractionDigits: digitCount,
}
const verbose = 0;

export const asEntryDate = (date: Date) => date ? format(new Date(date), entryDateFormat) : '';
export const asHistoryDate = (date: Date) => date ? format(new Date(date), historyDateFormat) : '';
export const today = startOfToday;
export const entryDateIsToday = (entryDate: string) => entryDate === asEntryDate(new Date());
export const previousDay = (date: Date|string) => add(new Date(date), { days: -1 });
export const nextDay = (date: Date, max?: Date) => {
  if (max && !isBefore(new Date(date), new Date(max))) {
    return date;
  }
  return add(new Date(date), { days: 1 });
}

export const fieldFragmentName = (fieldFragment: FieldFragment | ObjectWithId, lang?: keyof LanguageProperties) =>
  get(fieldFragment, `name.${lang || 'en'}`);


export const entityName = (entity: ObjectWithName, lang?: keyof LanguageProperties) => {
  if (lang === 'en') {
    return get(entity, 'name.en');
  }
  return get(entity, `name.${lang}`, get(entity, 'name.en'));
}

export const formatAmount = (amount:number|string|undefined, defaultValue: string = ''):string => {
  if (typeof amount === 'string') { return parseFloat(amount).toLocaleString(undefined, localNumberConfig); }
  if (typeof amount === 'number') { return amount.toLocaleString(undefined, localNumberConfig); }
  return defaultValue;
}

export const currency = (value:string|undefined) => {
  if (typeof value === 'string' && value.length !== 0) { return `${value} €` }
  return '';
}

export const valueSign = (value?:number) =>  {
  if (typeof value === 'number') {
    if (value >= 0) {
      return '+';
    } else {
      return '-';
    }
  }
  return '';
}

export const getName = (obj:any) => {
  if (typeof obj === 'undefined') {
    return '';
  }
  if (typeof obj === 'string') {
    return obj;
  }
  if (typeof obj.name === 'string') {
    return obj.name;
  }
  if (obj) {
    return obj?.name?.en;
  }
  return undefined;
}

export const canEditFieldByRole = (userRole:UserRole, fieldFragment:FieldFragment) => {
  return allRoles.indexOf(userRole) <= allRoles.indexOf(fieldFragment.editRole);
}

export const canViewFieldByRole = (userRole:UserRole, fieldFragment:FieldFragment) => {
  return allRoles.indexOf(userRole) <= allRoles.indexOf(fieldFragment.viewRole);
}


// Returns a date range where the Entry is editable for regular users or null if the date is invalid.
export const getEditableDateRangeForEntryDate = (entryDate:string):DateRange|null => {
  // Parse the input string as a Date object
  const startDate = new Date(entryDate + 'T00:00:00');

  // Check if the startDate is a valid date
  if (isNaN(startDate.getTime())) {
    if (verbose > 0) {
      console.error('Invalid date format');
    }
    return null;
  }

  // Create the end date by adding 1 hour to the next day
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 1); // Move to the next day
  endDate.setHours(15); // Set to 1 hour into the next day
  return { start: startDate, end: endDate };
}

export function fieldFragementsForShop(shops:EntryShop[]|null, shopId:string): FieldFragment[] {
  if (!shops) {
    return  [];
  }
  return shops.filter(shop => shop.id === shopId).reduce((acc, curr) => {
    if (curr.fieldDefinitions) {
      const fieldFragments = curr.fieldDefinitions.map(item => item.fieldFragments).flat();
      acc.push(...fieldFragments);
    }
    return acc;
  },[] as Array<FieldFragment>);
}

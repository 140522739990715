import { uniq } from 'lodash';
import uniqueId from 'lodash/uniqueId';

export function uniqueid(prefix: string = ''): string {
  if (prefix.split('-').length > 0) {
    console.warn("Avoid using '-' in ids.");
  }
  return uniqueId(prefix);
}

export function getFieldFragmentIds(items: Array<LayoutNode> | null): string[] {
  const result: string[] = [];
  if (!Array.isArray(items)) {
    return result;
  }

  function collectIds(nodes: LayoutNode[]) {
    for (const node of nodes) {
      if (node.type === 'FIELD_FRAGMENT' || node.type === 'COMPONENT') {
        result.push(node.id);
      }
      if (node.children && node.children.length > 0) {
        collectIds(node.children);
      }
    }
  }

  collectIds(items);
  return uniq(result);
}

export function findNodeWithId(items: Array<LayoutNode> | null, nodeId: string): LayoutNode | null {
  let result: LayoutNode | null = null;

  if (!Array.isArray(items)) {
    return result;
  }

  function collectIds(nodes: LayoutNode[]) {
    for (const node of nodes) {
      if (node.id === nodeId) {
        result = node;
        break;
      }
      if (node.children && node.children.length > 0) {
        collectIds(node.children);
      }
    }
  }

  collectIds(items);
  return result;
}

// Helper to transform previous data structure to new data structure
export function wrapColumnsInGroup(structure: LayoutNode[]): LayoutNode[] {
  return structure.map((element) => {
    if (element.type === 'ROW' && element.children) {
      const columnChildren = element.children.filter((child) => child.type === 'COLUMN');

      if (columnChildren.length > 0) {
        return {
          ...element,
          children: [
            {
              id: `GROUP-${element.id}`,
              type: 'GROUP',
              children: columnChildren,
            },
          ],
        };
      }
    }
    return element;
  });
}

export function dumpTypes(structure: LayoutNode[], indent: number = 0): string[] {
  let result: string[] = [];
  structure.forEach((element) => {
    result.push('  '.repeat(indent) + element.type + ' | ' + element.id);
    if (element.children) {
      dumpTypes(element.children, indent + 1);
      result = result.concat(dumpTypes(element.children, indent + 1));
    }
  });
  return result;
}

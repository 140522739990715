import { Drawer, Form, Input, Button, Select, Radio, RadioChangeEvent } from 'antd';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { getName } from '../utils';

interface ActionsDrawerProps {
  layoutTemplate: LayoutTemplate;
  layoutTemplates: Array<LayoutTemplate> | null;
  onClose: Function;
  onFieldDefinitionVisibilityChange: (fieldDefinition: FieldDefinition, value: LayoutConfigEnum | null ) => void;
  onNameChange: (value: string) => void;
  onCopyLayoutTemplate: (layoutTemplateId: string) => void;
  fieldDefinitions: FieldDefinition[] | null;
}

export const ActionsDrawer = ({
  layoutTemplate,
  layoutTemplates,
  onClose,
  onNameChange,
  onCopyLayoutTemplate,
  onFieldDefinitionVisibilityChange,
  fieldDefinitions,
}: ActionsDrawerProps) => {
  const [layoutTemplateLabels, setLayoutTemplateLabels] = useState<Array<SelectItem>>([]);
  const [selectedLayoutTemplateId, setSelectedLayoutTemplateId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (layoutTemplate && Array.isArray(layoutTemplates)) {
      setLayoutTemplateLabels(
        sortBy(layoutTemplates, 'name')
          // .filter(({ name }) => name !== '')
          .map(({ id, name }) => ({
            value: id,
            label: name,
            disabled: id === layoutTemplate.id,
          })),
      );
    }
  }, [layoutTemplates, layoutTemplate]);

  function onSubmit(values: any) {
    // If form input did not change values.templateName will be undefined
    onNameChange(values.templateName);
    onClose();
  }

  const localeOnChange = useCallback(({ target }: RadioChangeEvent) => {
    const fieldDefinition = fieldDefinitions?.find(item => item.id === target.name);
    if (fieldDefinition) {
      onFieldDefinitionVisibilityChange(fieldDefinition, target.value === "hide" ? "HIDE_END_IN_STATUS" : null);
    }
  }, [fieldDefinitions, onFieldDefinitionVisibilityChange]);

  const copyLayoutTemplate = useCallback(() => {
    if (selectedLayoutTemplateId) {
      onCopyLayoutTemplate(selectedLayoutTemplateId);
    }
  }, [selectedLayoutTemplateId, onCopyLayoutTemplate]);

  if (!layoutTemplate) {
    return null;
  }

  return (
    <Drawer
      title={<Trans i18nKey="layout-templates.title" />}
      size="large"
      placement="right"
      open={true}
      onClose={() => {
        onClose();
      }}
    >
      <Form layout="vertical" onFinish={onSubmit}>
        <Form.Item name="templateName" label="Template Name" initialValue={layoutTemplate.name}>
          <Input style={{ width: 'calc(100% - 200px)' }} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Update Template Name
        </Button>
      </Form>

      <hr className="mt-8 mb-8" />
      {/** select and copy other layoutTemplate */}
      <div>
        <div className="mb-2">Copy the layout from another template into this template.</div>
        <div>
          <Select style={{ width: 200 }} options={layoutTemplateLabels} onChange={setSelectedLayoutTemplateId} />
          <Button type="primary" disabled={!selectedLayoutTemplateId} onClick={copyLayoutTemplate}>
            Copy Layout
          </Button>
        </div>
      </div>

      <hr className="mt-8 mb-8" />
      <div>
        <div className="mb-4">Custom field "End" visibility in Register summary.</div>
        {fieldDefinitions && fieldDefinitions.map((fieldDefinition) => (
          <div className="flex gap-2 mt-2" key={fieldDefinition.id}>
            <div className="grid grid-cols-2 mb-2">
              <label>{getName(fieldDefinition)}</label>
              <Radio.Group
                options={['show', 'hide']}
                name={fieldDefinition.id}
                onChange={localeOnChange}
                defaultValue={(layoutTemplate.layoutConfig || []).find(item => item.key === fieldDefinition.id && item.value === "HIDE_END_IN_STATUS") ? "hide" : "show" }
                optionType="button"
              />
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

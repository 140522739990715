import { FieldFragment } from './FieldFragment';

interface FieldDefinitionProps {
  field: FieldDefinition;
  fieldFragmentVisibility: FieldFragmentVisibility[];
  values?: Map<string, number>;
  textValues?: Map<string, string>;
  onChange: Function;
  onClear: Function;
  canEdit: boolean;
  userRole: UserRole;
}

export const FieldDefinition = (props: FieldDefinitionProps) => {
  const visibleFields =
    props.field &&
    props.field.fieldFragments.filter((item) => {
      const fieldFragmentVisibility = props.fieldFragmentVisibility.find(
        (fieldFragmentVisibility) => fieldFragmentVisibility.fieldFragment.id === item.id,
      );
      const fieldFragmentVisibilityValue = fieldFragmentVisibility ? fieldFragmentVisibility.visibility : 'inherit';
      if (fieldFragmentVisibilityValue === 'inherit' && item.visibility === 'visible-by-default') {
        return true;
      }
      if (fieldFragmentVisibilityValue === 'visible') {
        return true;
      }
      return false;
    });
  if (visibleFields.length === 0) {
    return null;
  }
  return (
    <div className="p-6 bg-white">
      <h2>{props.field.name.current}</h2>
      <div className="grid grid-cols-2 gap-2">
        {visibleFields.map((item) => (
          <FieldFragment key={item.id} fieldFragment={item} {...props} />
        ))}
      </div>
    </div>
  );
};

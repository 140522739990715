import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToolBar } from '../../app/components/ToolBar';
import { Form } from './components/Form';
import { selectIsWaiting } from './layoutTemplatesSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { createLayoutTemplate } from './layoutTemplateActions';
import { cloneDeep } from 'lodash';
import { emptyTemplate } from './editor/data';

export function Add() {
  const dispatch = useAppDispatch();
  const isWaiting = useAppSelector(selectIsWaiting);
  const navigate = useNavigate();

  const [localeLayoutTemplate, setLocaleLayoutTemplate] = useState<LayoutTemplate>({
    name: '',
    nodes: emptyTemplate,
    layoutConfig: [],
  });

  const updateLayoutTemplate = useCallback(
    (key: string, value: string) => {
      setLocaleLayoutTemplate({ ...localeLayoutTemplate, [key]: value });
    },
    [localeLayoutTemplate],
  );

  const onSubmit = useCallback(async () => {
    const newLayoutTemplate = cloneDeep(localeLayoutTemplate);
    try {
      const result = await dispatch(createLayoutTemplate(newLayoutTemplate));
      // Stay on this route, toast with message should be visible.
      if (result.meta.requestStatus === 'rejected') {
        return;
      }
      if (result.payload.id) {
        return navigate(`/layout-templates/edit/${result.payload.id}`);
      }
      navigate('/layout-templates');
    } catch (err) {}
  }, [dispatch, navigate, localeLayoutTemplate]);

  return (
    <>
      <ToolBar
        title="layout-templates.add"
        nav={<Nav />}
        actions={<Actions onSave={onSubmit} disabled={isWaiting} />}
      />
      <div className="flex space-x-2 justify-between">
        <Form onSubmit={onSubmit} layoutTemplate={localeLayoutTemplate} onChange={updateLayoutTemplate} />
      </div>
    </>
  );
}

import { InputNumber, Input, Button, Tooltip } from 'antd';
import { formatAmount, canViewFieldByRole, canEditFieldByRole } from '../utils';
import { inputNumberMinValue, inputNumberPrecision } from '../constants';
import { useEffect, useState } from 'react';
import { CommonLayoutProps } from './LayoutComponent';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';

const { TextArea } = Input;

interface FieldFragmentProps extends CommonLayoutProps {
  fieldFragment: FieldFragment;
  labelPrefix?: string | React.ReactNode;
}

interface FieldFragmentNumberProps extends FieldFragmentProps {
  defaultValue: number;
  rawValue: number | null | undefined; // the value without defaulting to 0
  min?: number;
}

interface FieldFragmentTextProps extends FieldFragmentProps {
  defaultValue: string;
}

const tooltipColor = '#484343';

export const FieldFragmentNumber = (props: FieldFragmentNumberProps) => {
  const [value, setValue] = useState<string | number | null>(props.defaultValue);
  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);
  return (
    <div>
      <label>
        <>
          {props.labelPrefix}
          {props.fieldFragment.name.current}
        </>
      </label>
      {props.fieldFragment.description && (
        <div className="text-gray-500 text-sm">{props.fieldFragment.description.current}</div>
      )}
      {props.canEdit && (
        <InputNumber
          addonAfter="€"
          value={value}
          precision={inputNumberPrecision}
          min={typeof props.min === 'number' ? props.min : inputNumberMinValue}
          onChange={(value) => {
            props.onChange(props.fieldFragment, value);
          }}
        />
      )}
      {!props.canEdit && (
        <div className="flex gap-2">
          <span>{formatAmount(props.defaultValue)}</span>
          <span>€</span>
          {typeof props.rawValue === 'number' && props.rawValue === 0 && (
            <Tooltip title={<Trans i18nKey="entries.value.edit-tooltip" />} color={tooltipColor} key={tooltipColor}>
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </div>
      )}

      {typeof props.rawValue === 'number' && props.rawValue === 0 && props.canEdit && (
        <div className="inline-flex gap-x-1">
          <Button
            onClick={() => {
              props.onClear(props.fieldFragment);
              setValue(null);
            }}
            type="primary"
          >
            <Trans i18nKey="entries.value.clear" />
          </Button>
          <Tooltip
            title="This value is explicitly set to 0, not an empty or default value. Use clear to remove the value"
            color={tooltipColor}
            key={tooltipColor}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export const FieldFragmentText = (props: FieldFragmentTextProps) => (
  <div>
    <label>
      <>
        {props.labelPrefix}
        {props.fieldFragment.name.current}
      </>
    </label>
    {props.fieldFragment.description && (
      <div className="text-gray-500 text-sm">{props.fieldFragment.description.current}</div>
    )}
    {props.canEdit && (
      <TextArea
        defaultValue={props.defaultValue}
        onChange={(event) => props.onChange(props.fieldFragment, event.currentTarget.value)}
        showCount={true}
        rows={6}
        maxLength={512}
      />
    )}
    {!props.canEdit && <div>{props.defaultValue}</div>}
  </div>
);

export const FieldFragment = (props: FieldFragmentProps) => {
  const canEdit = props.canEdit && canEditFieldByRole(props.userRole, props.fieldFragment);
  const canView = canViewFieldByRole(props.userRole, props.fieldFragment);
  if (!canView) {
    return null;
  }
  if (props.fieldFragment.inputType === 'number') {
    return (
      <FieldFragmentNumber
        {...props}
        canEdit={canEdit}
        defaultValue={(props.values && props.values.get(props.fieldFragment.id)) || 0}
        rawValue={props.values && props.values.get(props.fieldFragment.id)}
      />
    );
  } else if (props.fieldFragment.inputType === 'text') {
    return (
      <FieldFragmentText
        {...props}
        canEdit={canEdit}
        defaultValue={(props.textValues && props.textValues.get(props.fieldFragment.id)) || ''}
      />
    );
  }
  return null;
};
